<script>
import OnboardingHeading from '../../registration/OnboardingHeading.vue'

export default {
  name: "SignUpPage",

  components: {
    OnboardingHeading
  },

  methods: {
    goToCreateAccount(){
      this.$router.push({ name: 'registration.woocommerce.create-account'} )
    },

    redirectToShopifySyncioApp(){
      window.location.href = 'https://apps.shopify.com/syncio'
    }
  }
}
</script>

<template>
  <section class="onboarding v2">
    <OnboardingHeading heading="Welcome, Let's get started!" />

    <p class="onboarding-text m-t-2">
      Free 3 month trial for WooCommerce Destination accounts!
    </p>

    <aside class="login-options d-flex justify-center registration m-b-6">
      <div class="woo-login d-flex" @click="goToCreateAccount">
        <img src="../../../assets/images/woo_logo.png" alt="Woo logo" />
        <span class="text">WooCommerce</span>
      </div>
      <div class="shopify-login d-flex" @click="redirectToShopifySyncioApp">
        <img src="../../../assets/images/shopify_logo.png" alt="shopify logo" />
        <span class="text">Shopify</span>
      </div>
    </aside>

    <aside class="d-flex justify-center">
      <router-link to="/login" class="register-btn">
        Already have an account? Login here
      </router-link>
    </aside>
  </section>
</template>
